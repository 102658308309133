<template>
	<div id="teacherleave">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">签到列表</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content u-f">
				<!-- 流程申请 -->
				<div class="content-right">
					<div class="title u-f-item u-f-jsb">
						<div>共{{ total }}条数据</div>
						<div class="u-f-item">
							<el-select clearable v-model="gradeId" placeholder="请选择年级" size="small" style="width: 120px;margin-right: 10px;" @change="gradecn1">
							   <el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
							<el-select clearable v-model="calssname" placeholder="请选择班级" size="small" style="width: 120px;margin-right: 10px;" @change="classcn1">
							   <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
							<el-button size='small' type="primary" @click="sosuo">筛选</el-button>
						</div>
					</div>
					<el-table
						:data="list"
						height="98%"
						ref="multipleTable"
						tooltip-effect="dark"
						style="width: 99.6%;border: 1px solid #EEEEEE;"
					>
						<el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
						<el-table-column prop="title" label="类型" align="center">
							<template slot-scope="scope">
								<span>{{typeAttr[scope.row.type]}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="number" label="第几节" align="center"></el-table-column>
						<el-table-column prop="class_name" label="班级" align="center"></el-table-column>
						<el-table-column prop="" label="星期" align="center">
							<template slot-scope="scope">
								<span>{{dayAttr[scope.row.day_number]}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="evening_study_type" label="晚修类型" align="center"></el-table-column>
						<el-table-column prop="user_name" label="老师" align="center"></el-table-column>
						<el-table-column prop="teacher_type" label="老师类型" align="center"></el-table-column>
						<el-table-column prop="status" label="签到状态" align="center">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.status == 1">正常</el-tag>
								<el-tag v-if="scope.row.status == 2">缺勤</el-tag>
								<el-tag v-if="scope.row.status == 3">请假</el-tag>
								<el-tag v-if="scope.row.status == 4">补登</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="createtime" label="签到时间" align="center"></el-table-column>
						
						<el-table-column prop="updatetime" label="更新时间" align="center"></el-table-column>
						
						<el-table-column prop="remark" label="备注" align="center"></el-table-column>
						
						<el-table-column align="center" label="操作" width="150">
							<template slot-scope="scope">
								<el-button @click="openDetails(scope.row)" type="text" size="small">编辑</el-button>
								</template>
						</el-table-column>
					</el-table>
					<div class="u-f-right" style="padding: 15px 0;">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>
			
			<!-- 详情 -->
			<el-dialog title="签到编辑" :visible.sync="activityVisible" width="650px" :before-close="handleClose">
				<el-form>
					<el-form-item label="状态" label-width="100px">
						  <el-radio-group v-model="curStatus">
						    <el-radio :label="1">正常</el-radio>
						    <el-radio :label="2">缺勤</el-radio>
						    <el-radio :label="3">请假</el-radio>
						    <el-radio :label="4">补登</el-radio>
						  </el-radio-group>
					</el-form-item>
					<el-form-item label="备注" label-width="100px">
						  <el-input
						    type="textarea"
						    :rows="2"
						    placeholder="请输入备注"
						    v-model="remark">
						  </el-input>
					</el-form-item>
				</el-form>
				
				<span slot="footer" class="dialog-footer">
				    <el-button @click="activityVisible = false">取 消</el-button>
				    <el-button type="primary" @click="sureSign">确 定</el-button>
				  </span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			meunLeftIndex: 1,
			typeAttr:['','早读','午练','晚修'],
			dayAttr:['星期天','星期一','星期二','星期三','星期四','星期五','星期六'],
			total: 0,
			list:[],
			page:1,
			limit:11,
			gradeList:[],
			classList:[],
			class_id:'',
			class_name:'',
			curStatus:1,
			remark:'',
			activityVisible:false,
			info:{},
			username:''
		};
	},
	filters:{
		getLocalTime(nS) {  
			return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');  
		}
	},
	mounted() {
		this.getleaveindex();
		this.getgradeList()
        this.getClassList()
	},
	methods: {
		gradecn1(){
			this.page = 1;
			this.calssname = '';
			this.getClassList()
			this.getleaveindex()
		},
		// 获取年级列表
		getgradeList(){
			this.$api.setting.getGradeList({}).then(res=>{
				if(res.data.code==1){
					this.gradeList = res.data.data.rows;
				}
			})
		},
		// 获取班级列表
		getClassList(){
			this.$api.setting.getClassList({
				// filter: JSON.stringify({'grade_id': this.grade_id||this.gradeId})
			}).then(res=>{
				this.classList = res.data.rows;
			})
		},
		classcn1(){
			this.page = 1;
			this.getleaveindex()
		},
		sosuo(){
			this.page = 1;
			this.getleaveindex();
		},
		print(e) {
		    let {href}= this.$router.resolve({
				path: "/print",
				query: {
				    id: e.id,
					type:2
				}
			});
			window.open(href, '_blank');
		},
		// 详情
		openDetails(e) {
					this.info =JSON.parse(JSON.stringify(e));
					this.remark=''
					this.activityVisible = true;
		},
		sureSign(){
			
			
			this.$api.sign.signEdit({
				id:this.info.id,
				remark:this.remark,
				status:this.curStatus
			}).then(res=>{
				if(res.data.code==1){
		this.getleaveindex();
					this.activityVisible = false;
				}else{
						this.$message.error(res.data.msg)
					}
			})
		},
		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getleaveindex();
		},
		getleaveindex(type){
			let data = {
				page:this.page,
				limit:this.limit
			}
			if(this.calssname){data.filter =JSON.stringify({class_id:this.calssname,grade_id: this.gradeId})}
			this.$api.sign.signAll(data).then(res=>{
				if(res.data.code==1){
					this.list = res.data.data.rows;
					this.total = res.data.data.total;
				}
			})
		},
		handleClose(done) {
			done();
		},
		// 请假详情
		leaveDetails(id) {
			this.$api.rule.leaveDetails({ id: id }).then(res => {
				if (res.data.code == 1) {
					this.info = res.data.data;
					this.activityVisible = true;
				}
			});
		},
		// 作废
		destroy(id){
			this.$confirm('确认要作废吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				this.$api.rule.leaveDestroy({id:id}).then(res=>{
					if(res.data.code==1){
						this.$message.success('操作成功')
						this.page = 1;
						this.getleaveindex()
					}else{
						this.$message.error(res.data.msg)
					}
				})
			}).catch(() => {});
		}
	}
};
</script>

<style lang="scss">
.el_option {
	display: none !important;
}
#teacherleave {
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.52vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.53125vw;
			}
		}
	}
	.content {
		background-color: #ffffff;
		height: 85vh;
		margin-top: 20px;
		.content-right {
			width: 100%;
			height: 74vh;
			padding: 15px 20px;
			.title {
				padding: 0 0 15px 0;
				.btn {
					background-color: #f5f5f5;
					padding: 5px;
				}
				.btn:hover {
					color: #4998ff;
					background: #e4f1ff;
					cursor: pointer;
				}
			}
			.el-col {
				margin-bottom: 20px;
			}
			.content-item {
				border: 2px solid #f2f3f4;
				.box1 {
					padding: 35px 20px;
					i {
						color: #1890ff;
						margin-right: 5px;
						font-size: 20px;
					}
					span {
						font-weight: bold;
					}
				}
				.btn {
					background-color: #fafafa;
					padding: 10px 0;
					color: #b5bdc2;
					cursor: pointer;
				}
			}
		}
	}

	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding:20px 20px 35px 20px;
		overflow-y: auto;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					width: 20%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.input-lebal1 {
					width: 5vw;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.upload-file {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					opacity: 0;
					cursor: pointer;
				}
				.file_box {
					padding: 5px;
					.el-icon-circle-check {
						display: block;
					}
					.el-icon-circle-close {
						display: none;
					}
				}
				.file_box:hover {
					color: #007aff;
					background-color: #f6f8fb;
					.el-icon-circle-check {
						display: none;
					}
					.el-icon-circle-close {
						display: block;
					}
				}
				.el-upload__tip {
					color: #ccc;
				}

				.table-head {
					height: 50px;
					min-height: 21.33333333px;
					background-color: #f6f8fb;
					border-bottom: 1px solid #f0f2f5;
					.class_name {
						font-weight: 700;
						height: 100%;
						border-right: 1px solid #f0f2f5;
						width: 4.85416667vw;
						min-width: 39.46666667px;
						color: #68b0fd;
						font-size: 0.729vw;
					}
					.week_name {
						height: 100%;
						border-right: 1px solid #f0f2f5;
						flex: 1;
						min-width: 64px;
						border-right: 1px solid #f0f2f5;
						color: #8b8b8d;
						font-size: 0.7291666666666666vw;
					}
				}
				.table-item {
					height: 50px;
					min-height: 26.66666667px;
					border-bottom: 1px solid #f0f2f5;
					.time_name {
						height: 100%;
						background-color: #f6f8fb;
						width: 4.85416667vw;
						min-width: 39.46666667px;
						border-right: 1px solid #f0f2f5;
						color: #8b8b8d;
						font-size: 0.7291666666666666vw;
					}
					.cell {
						position: relative;
						overflow-x: hidden;
						height: 100%;
						border-right: 1px solid #f0f2f5;
						width: 6.25vw;
						flex: 1;
						min-width: 64px;
						cursor: pointer;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-pack: center;
						-ms-flex-pack: center;
						justify-content: center;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						-webkit-box-orient: vertical;
						-webkit-box-direction: normal;
						-ms-flex-direction: column;
						flex-direction: column;
						transition: all 0.2s ease 0s;
						-moz-transition: all 0.2s ease 0s;
						-webkit-transition: all 0.2s ease 0s;
						-o-transition: all 0.2s ease 0s;
						-ms-transition: all 0.2s ease 0s;
						p {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							width: 4.94791667vw;
							min-width: 50.66666667px;
							margin: 0;
							padding: 0;
							border: 0;
							text-align: center;
							// font-size: 0.7291666666666666vw;
						}
						.cell_subject {
							color: #6f6f6f;
							margin-bottom: 0.26041667vw;
						}
						.cell_teacher {
							color: #a9b8c0;
						}
					}
					.active {
						background-color: #1890ff;
						.cell_teacher {
							color: #ffffff;
						}
						.cell_subject {
							color: #ffffff;
						}
					}
				}
				.teacher-list {
					display: flex;
					flex-wrap: wrap;
					width: 72%;
					.teacher-item {
						width: 100px;
						height: 30px;
						line-height: 30px;
						border-radius: 5px;
						text-align: center;
						margin-right: 10px;
						background-color: #eeeeee;
						margin-bottom: 10px;
						cursor: pointer;
					}
					.active {
						background-color: #007aff;
						color: #ffffff;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
	#audit {
		.el-dialog__body {
			padding: 0 20px 35px 20px;
			height: 300px;
		}
	}
}
.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.el-step__description{
	padding-right: 0!important;
}
</style>
